/* ./src/index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    code {
        @apply bg-black text-white p-4 text-sm rounded;
        font-family: "Courier New", Courier, monospace;
    }
    pre {
      @apply bg-black text-white p-4 text-sm rounded;
        font-family: "Courier New", Courier, monospace;
    }
  }